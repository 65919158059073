<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        People
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <!-- <button
        @click="addPerson()"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14" />
        </svg>
        <span>Add Person</span>
      </button> -->
    </div>

    <div class="flex flex-wrap">
      <vue-good-table
        v-if="people.length > 0"
        class="w-full"
        styleClass="vgt-table condensed"
        mode="remote"
        :rows="people"
        :columns="columns"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'is_default'">
            <span
              class="px-2 rounded-lg"
              :class="{
                'bg-yellow-200 text-yellow-700': props.row.is_default == false,
                'bg-green-200 text-green-700': props.row.is_default == true,
              }"
              >{{ props.row.is_default ? "Default" : "Not Default" }}</span
            >
          </span>
          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
        </template>
      </vue-good-table>
      <p class="mx-auto my-16" v-if="people.length == 0">
        There are no People on this Contact.
      </p>
    </div>

    <portal to="overlay-outlet">
      <panel
        :showing="addEditContactPanelOpen"
        @close="addEditContactPanelOpen = false"
        :title="editingContact.contact_id ? 'Edit Contact' : 'Add Contact'"
      >
        <add-edit-contact :contact="editingContact" @complete="saveContact" />
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <confirm-alert
        :showingConfirm="confirmAlert.showing"
        :message="confirmAlert.message"
        @complete="confirmAlert.resultFunction"
      />
    </portal>
  </div>
</template>

<script>
const Notification = () => import("@/components/Notification.vue");
const ConfirmAlert = () => import("@/components/ConfirmAlert.vue");
const Panel = () => import("@/components/Panel.vue");
const AddEditContact = () =>
  import("@/components/SalesProspects/Edit/AddEditContact.vue");

export default {
  name: "People",
  components: {
    Notification,
    ConfirmAlert,
    Panel,
    AddEditContact,
  },
  props: {
    people: Array,
    contactId: String,
  },
  data() {
    return {
      addEditContactPanelOpen: false,
      editingContact: {},
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
      columns: [
        {
          label: "First Name",
          field: "firstname",
        },
        {
          label: "Last name",
          field: "lastname",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Mobile",
          field: "mobile",
        },
        {
          label: "Telephone",
          field: "telephone",
        },
        {
          label: " ",
          field: "is_default",
        },
      ],
    };
  },
  methods: {
    addPerson: function() {
      this.editingContact = {};
      this.addEditContactPanelOpen = true;
    },
    editContact: function(contact) {
      this.editingContact = { ...contact };
      this.addEditContactPanelOpen = true;
    },
    saveContact: async function(contact) {
      // Ensure contact has mandatory fields filled out
      if (!contact.email & !contact.telephone) {
        let msg = contact.is_default
          ? "Please add either an email or telephone for the primary contact"
          : "Please add either an email or telephone for the additional contact";

        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "warning",
                  title: "Error",
                  close: onClose,
                },
              },
              "Missing fields"
            );
          },
          {
            position: "top-right",
          }
        );
      }

      this.addEditContactPanelOpen = false;

      try {
        if (!contact.contact_id) {
          contact.is_default = false;
          let createResult = await this.SalesProspectService.createProspectContact(
            this.enquiryId,
            contact
          );
          contact.contact_id = createResult.data.contact_id;
          this.contacts.push(contact);
        } else {
          await this.SalesProspectService.updateProspectContact(
            this.enquiryId,
            contact.contact_id,
            contact
          );
          let idx = this.$_.findIndex(
            this.contacts,
            (c) => c.contact_id == contact.contact_id
          );
          this.contacts.splice(idx, 1, contact);
        }

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Details Saved",
                  close: onClose,
                },
              },
              "Contact details saved"
            );
          },
          {
            position: "top-right",
          }
        );
      } catch (err) {
        console.error(err);

        let msg = !contact.contact_id
          ? "There was a problem creating the new contact"
          : "There was a problem updating the contact";

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "There was a problem updating the contact"
            );
          },
          {
            position: "top-right",
          }
        );
      }
    },
    handlePromoteContactToPrimary: async function(result) {
      if (result) {
        this.confirmAlert.showing = false;
        try {
          this.confirmAlert.data.is_default = true;
          await this.saveContact(this.confirmAlert.data);
          this.contacts.forEach((c) => {
            if (c.contact_id !== this.confirmAlert.data.contact_id) {
              c.is_default = false;
            }
          });
        } catch (error) {
          console.error(error);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "There was a problem updating the contact"
              );
            },
            {
              position: "top-right",
            }
          );
        }
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    premotePersonToDefault: function(contact) {
      this.confirmAlert = {
        resultFunction: this.handlePromoteContactToPrimary,
        message: `Change primary contact to '${contact.firstname} ${contact.lastname}'?`,
        showing: true,
        data: contact,
      };
    },
    handleDeleteContact: async function(result) {
      if (result) {
        this.confirmAlert.showing = false;
        try {
          this.isBusy = true;
          await this.SalesProspectService.deleteProspectContact(
            this.enquiryId,
            this.confirmAlert.data.contact_id
          );
          let idx = this.$_.findIndex(this.contacts, (c) => {
            return c.contact_id == this.confirmAlert.data.contact_id;
          });

          this.$delete(this.contacts, idx);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Contact Deleted",
                    close: onClose,
                  },
                },
                "Successfully deleted contact"
              );
            },
            {
              position: "top-right",
            }
          );
        } catch (err) {
          console.error(err);

          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "There was a problem deleting the contact"
              );
            },
            {
              position: "top-right",
            }
          );
        }

        this.isBusy = false;
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    deleteContact: function(contact) {
      // If we're deleting a contact that hasn't been saved yet
      if (String(contact.contact_id).startsWith("pending")) {
        let idx = this.$_.findIndex(this.contacts, (c) => {
          return c.contact_id == contact.contact_id;
        });

        this.$delete(this.contacts, idx);
        return;
      }

      this.confirmAlert = {
        resultFunction: this.handleDeleteContact,
        message: `Are you sure you wish to delete '${contact.firstname} ${contact.lastname}' This cannot be undone.`,
        showing: true,
        data: contact,
      };
    },
  },
};
</script>
